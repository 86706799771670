export const physicalProductStates = [
  {
    key: 1,
    value: 'Sólido'
  },
  {
    key: 2,
    value: 'Líquido'
  },
  {
    key: 3,
    value: 'Gasoso',
  },
]
